import axios from "axios";
import millify from "millify";
import buyerRouter from "./router";
import { Transaction } from "./subcomponents/transactions";
// import Vue from 'vue';
const axiosApi = axios.create()
axiosApi.interceptors.request.use(
    async config => {
        const apiKey = localStorage.getItem("access_token");
        config.headers = {
            'Authorization': `Bearer ${apiKey}`,
            'Accept': 'application/json',
        }
        return config;
    },
)
/**
 * @param {number} amount
 * @returns {number}
 */
function std_fig(amount) {
    return millify(amount,{precision:2});
}
// const wallet_plugin = Vue.mixin({
//     methods:{

//     }
// })
const BUYING_AGENT_URL = process.env.VUE_APP_BACKEND + "/buyers";
const OFFTAKER_URL = (project_id)=>process.env.VUE_APP_BACKEND+"/"+`P-${project_id}`+"/partners";
const OFFTAKER_WALLET = (project_id)=>OFFTAKER_URL(project_id)+"/"+"wallet";
const BUYER_URL = (buyer_id)=>BUYING_AGENT_URL+"/"+ buyer_id;
export {axiosApi,BUYING_AGENT_URL,OFFTAKER_URL,OFFTAKER_WALLET,buyerRouter,std_fig,BUYER_URL,Transaction};