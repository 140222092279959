
const Home = () => import("./subroutes/home/Home.vue");
const BuyersAgents = () => import("./subroutes/agents/Agents.vue");
const TransactionHistory = () => import("./subroutes/history/History.vue");
const AgentInfo = () => import("./subroutes/info/Info.vue")
const Buyer =  () => import("./Buyer.vue");
const router = {
    path: ":buyer_id",
    component: Buyer,
    children:[
        {
            path:"",
            name:"buyer-home",
            component:Home,
        },
        {
            path:"agents",
            name:"buyer-agents",
            component:BuyersAgents,
        },
        {
            path:"history",
            name:"buyer-history",
            component:TransactionHistory
        },
        {
            path:"info",
            name:"buyer-info",
            component:AgentInfo,
        }
    ]
};
export default router