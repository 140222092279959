/**
 * @param {string} base_url
 * @param {number} project_id
 * @description prepends project_id to the base_url
 * @returns {string}
 */ 
 function add_pid(base_url,project_id){
    let format_pid = "P-"+project_id.toString()
    const url_1 = process.env.VUE_APP_BACKEND
    const url_2 = url_1+ `/${format_pid}`;
    return base_url.replace(url_1,url_2)
}
/**
* @param {string} field
* @description where field is an object key in array of objects.
*/
function pluck(field){
    return (x) => {         
        return x[field] 
    } 
}
/**
* @param {Array<string>} fields
* @description same as pluck but for multiple fields
*/
function pluck_many(fields){
    return (x)=>{
        return fields.map(field=>{return x[field]})
    }
}
/**
* @param {number} nm
*/
function to_tonnes(nm){
    const tonnage = nm/1000;
    return parseFloat(tonnage.toPrecision(3));
}
export {add_pid,pluck,pluck_many,to_tonnes}
