import axios from "axios";
const axiosApiInstance = axios.create();
const axiosApiInst = axios.create();
axiosApiInstance.interceptors.request.use(
    async config => {
        const apiKey = localStorage.getItem("access_token");
        config.headers = {
            ...config.headers.common,
            'Authorization': `Bearer ${apiKey}`,
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        return config;
    },
)
axiosApiInst.interceptors.request.use(
    async config => {
        const apiKey = localStorage.getItem("access_token");
        config.headers = {
            'Authorization': `Bearer ${apiKey}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
        return config;
    },
)
axiosApiInstance.interceptors.response.use((response) => {
    return response
}, async function (error) {
        if (
            error.response.status === 401 || 
            error.response.status===403
        ) {
            localStorage.clear();
            window.location.href = window.location.origin + "/login";

        }
        else{
            throw error;
        }
    
});
axiosApiInst.interceptors.response.use((response) => {
    return response
    
// }, async function (error) {
//     try {
//         if (
//             error.response.status === 401 || 
//             error.response.status===403 ||
//             (error.response.status===422)
//         ) {
//             localStorage.clear();
//             window.location.href = window.location.origin + "/login";

//         }

//     } catch (error) {
//         return Promise.reject(error);
//     }

});
export {axiosApiInstance,axiosApiInst}