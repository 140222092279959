<template>
  <div>
    <div class="bg-light login-ui">
      <div class="row m-0">
        <div class="col-12 col-md bg-primary-dark p-0">
          <div class="full-height">
            <div class="top-left px-lg-5">
              <div class="content">
                <img src="@/assets/logo.png" height="80px" alt="" />
                <h1>Africa’s Leading Produce Bulking Platform</h1>
                <h4 class="text-large mb-4">
                  Record and Pay for Produce using the easy-to-use, web-based
                  off-take system.
                </h4>
                <img src="@/assets/screens.png" height="200px" alt="" />
              </div>
            </div>

            <div class="text-center p-lg-5">
              <br />
              <p class="text-large- text-white">
                Are you an exporter, agribusiness with out-growers or a
                commodity trader looking for integrated end-to-end management of
                your farmers, input advances, produce bulking and purchasing?
                Look no further!
              </p>
              <!-- <a  class="btn btn-lg btn-light mr-3" @click="requestDemo">Request Demo</a> -->
              <a
                class="btn btn-lg btn-light mr-3"
                href="https://ezyproduce.ezyagric.com/register.html"
                >Request Demo</a
              >
              <a
                class="btn btn-lg btn-outline-light"
                href="https://ezyproduce.ezyagric.com/"
                >Learn More</a
              >
            </div>
          </div>
        </div>
        <div class="col-12 col-md">
          <div class="full-height">
            <div class="login-form">
              <h4 class="font-weight-bold py-0 text-center-">
                Login to your Offtaker account
              </h4>
              <b-alert v-model="unauthorized" variant="danger" dismissible>
                Incorrect username or password.
              </b-alert>
              <b-alert :show="retrytime" variant="warning">
                <vue-countdown :time="retrytime * 1000">
                  <template slot-scope="props">
                    Too many attempts try again in {{ props.seconds }} seconds.
                  </template>
                </vue-countdown>
              </b-alert>
              <b-form @submit.prevent="login">
                <b-form-group>
                  <!-- <label>Username or Email</label> -->
                  <b-form-input
                    v-model="username"
                    placeholder="Username or Email"
                    type="text"
                    autocomplete="off"
                    required
                    min="8"
                  ></b-form-input>
                </b-form-group>
                <b-form-group>
                  <!-- <label>Password</label> -->
                  <b-form-input
                    v-model="password"
                    type="password"
                    autocomplete="off"
                    required
                    placeholder="Password"
                    min="8"
                  ></b-form-input>
                </b-form-group>
                <div class="d-flex">
                  <b-col>
                    <b-button
                      type="submit"
                      variant="primary"
                      squared
                      class="w-100"
                      :disabled="retrytime > 100"
                      ><b-spinner
                        small
                        label="Small Spinner"
                        v-show="isAuthing"
                      ></b-spinner>
                      &nbsp; LOG IN</b-button
                    >
                  </b-col>
                  <!-- <b-col>
                    <b-button
                      class="w-100"
                      variant="outline-primary"
                      @click="sso_logIn"
                      >SSO LOG IN</b-button
                    >
                  </b-col> -->
                </div>

                <div class="my-1">
                  <!-- <h6 class="text-center font-weight-bold text-secondary">OR</h6> -->
                </div>
                <a
                  @click="forgot_password"
                  variant="outline-primary"
                  squared
                  class="w-100 text-primary link"
                  >Forgot Password?</a
                >
              </b-form>
            </div>
            <div class="bottom text-center">
              <div class="h-menu">
                <a href="https://about.ezyagric.com/" target="_blank"
                  >About EzyAgric</a
                >
                <!-- <a href="">Terms of Use</a> -->
                <a
                  href="https://about.ezyagric.com/public/docs/privacy-policy.pdf"
                  target="_blank"
                  >Privacy Policy</a
                >
              </div>
              <p class="text-muted text-small mt-3">
                Copyright 2022 EzyAgric Limited
              </p>
            </div>
          </div>
        </div>
      </div>

      <forgot-password></forgot-password>
      <div>
        <b-modal
          id="request-demo"
          title="false"
          centered
          hide-footer
          hide-header
        >
          <div class="head position-relative bg-primary p-3 text-white">
            <h4 class="m-0">Request a demo</h4>
            <p class="m-0">Get to try out offtaker for free</p>
            <a href="" class="bi bi-close"></a>
          </div>
          <form @submit.prevent="requestForDemo">
            <div class="p-3">
              <label for="" class="control-label">Name</label>
              <input
                type="text"
                class="form-control"
                v-model="form.name"
                required
              />

              <label for="" class="control-label">Organization</label>
              <input
                type="text"
                class="form-control"
                v-model="form.organization"
                required
              />

              <label for="" class="control-label">Email</label>
              <input
                type="email"
                class="form-control"
                v-model="form.email"
                required
              />

              <label for="" class="control-label">Country</label>

              <select
                type="text"
                class="form-control"
                v-model="form.country"
                required
              >
                <option value="">Select Country</option>
                <option>country</option>
                <option value="Afghanistan">Afghanistan</option>
                <option value="Aland Islands">Aland Islands</option>
                <option value="Albania">Albania</option>
                <option value="Algeria">Algeria</option>
                <option value="American Samoa">American Samoa</option>
                <option value="Andorra">Andorra</option>
                <option value="Angola">Angola</option>
                <option value="Anguilla">Anguilla</option>
                <option value="Antarctica">Antarctica</option>
                <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                <option value="Argentina">Argentina</option>
                <option value="Armenia">Armenia</option>
                <option value="Aruba">Aruba</option>
                <option value="Australia">Australia</option>
                <option value="Austria">Austria</option>
                <option value="Azerbaijan">Azerbaijan</option>
                <option value="Bahamas">Bahamas</option>
                <option value="Bahrain">Bahrain</option>
                <option value="Bangladesh">Bangladesh</option>
                <option value="Barbados">Barbados</option>
                <option value="Belarus">Belarus</option>
                <option value="Belgium">Belgium</option>
                <option value="Belize">Belize</option>
                <option value="Benin">Benin</option>
                <option value="Bermuda">Bermuda</option>
                <option value="Bhutan">Bhutan</option>
                <option value="Bolivia">Bolivia</option>
                <option value="Bonaire, Sint Eustatius and Saba">
                  Bonaire, Sint Eustatius and Saba
                </option>
                <option value="Bosnia and Herzegovina">
                  Bosnia and Herzegovina
                </option>
                <option value="Botswana">Botswana</option>
                <option value="Bouvet Island">Bouvet Island</option>
                <option value="Brazil">Brazil</option>
                <option value="British Indian Ocean Territory">
                  British Indian Ocean Territory
                </option>
                <option value="Brunei Darussalam">Brunei Darussalam</option>
                <option value="Bulgaria">Bulgaria</option>
                <option value="Burkina Faso">Burkina Faso</option>
                <option value="Burundi">Burundi</option>
                <option value="Cambodia">Cambodia</option>
                <option value="Cameroon">Cameroon</option>
                <option value="Canada">Canada</option>
                <option value="Cape Verde">Cape Verde</option>
                <option value="Cayman Islands">Cayman Islands</option>
                <option value="Central African Republic">
                  Central African Republic
                </option>
                <option value="Chad">Chad</option>
                <option value="Chile">Chile</option>
                <option value="China">China</option>
                <option value="Christmas Island">Christmas Island</option>
                <option value="Cocos (Keeling) Islands">
                  Cocos (Keeling) Islands
                </option>
                <option value="Colombia">Colombia</option>
                <option value="Comoros">Comoros</option>
                <option value="Congo">Congo</option>
                <option value="Congo, Democratic Republic of the Congo">
                  Congo, Democratic Republic of the Congo
                </option>
                <option value="Cook Islands">Cook Islands</option>
                <option value="Costa Rica">Costa Rica</option>
                <option value="Cote D'Ivoire">Cote D'Ivoire</option>
                <option value="Croatia">Croatia</option>
                <option value="Cuba">Cuba</option>
                <option value="Curacao">Curacao</option>
                <option value="Cyprus">Cyprus</option>
                <option value="Czech Republic">Czech Republic</option>
                <option value="Denmark">Denmark</option>
                <option value="Djibouti">Djibouti</option>
                <option value="Dominica">Dominica</option>
                <option value="Dominican Republic">Dominican Republic</option>
                <option value="Ecuador">Ecuador</option>
                <option value="Egypt">Egypt</option>
                <option value="El Salvador">El Salvador</option>
                <option value="Equatorial Guinea">Equatorial Guinea</option>
                <option value="Eritrea">Eritrea</option>
                <option value="Estonia">Estonia</option>
                <option value="Ethiopia">Ethiopia</option>
                <option value="Falkland Islands (Malvinas)">
                  Falkland Islands (Malvinas)
                </option>
                <option value="Faroe Islands">Faroe Islands</option>
                <option value="Fiji">Fiji</option>
                <option value="Finland">Finland</option>
                <option value="France">France</option>
                <option value="French Guiana">French Guiana</option>
                <option value="French Polynesia">French Polynesia</option>
                <option value="French Southern Territories">
                  French Southern Territories
                </option>
                <option value="Gabon">Gabon</option>
                <option value="Gambia">Gambia</option>
                <option value="Georgia">Georgia</option>
                <option value="Germany">Germany</option>
                <option value="Ghana">Ghana</option>
                <option value="Gibraltar">Gibraltar</option>
                <option value="Greece">Greece</option>
                <option value="Greenland">Greenland</option>
                <option value="Grenada">Grenada</option>
                <option value="Guadeloupe">Guadeloupe</option>
                <option value="Guam">Guam</option>
                <option value="Guatemala">Guatemala</option>
                <option value="Guernsey">Guernsey</option>
                <option value="Guinea">Guinea</option>
                <option value="Guinea-Bissau">Guinea-Bissau</option>
                <option value="Guyana">Guyana</option>
                <option value="Haiti">Haiti</option>
                <option value="Heard Island and Mcdonald Islands">
                  Heard Island and Mcdonald Islands
                </option>
                <option value="Holy See (Vatican City State)">
                  Holy See (Vatican City State)
                </option>
                <option value="Honduras">Honduras</option>
                <option value="Hong Kong">Hong Kong</option>
                <option value="Hungary">Hungary</option>
                <option value="Iceland">Iceland</option>
                <option value="India">India</option>
                <option value="Indonesia">Indonesia</option>
                <option value="Iran, Islamic Republic of">
                  Iran, Islamic Republic of
                </option>
                <option value="Iraq">Iraq</option>
                <option value="Ireland">Ireland</option>
                <option value="Isle of Man">Isle of Man</option>
                <option value="Israel">Israel</option>
                <option value="Italy">Italy</option>
                <option value="Jamaica">Jamaica</option>
                <option value="Japan">Japan</option>
                <option value="Jersey">Jersey</option>
                <option value="Jordan">Jordan</option>
                <option value="Kazakhstan">Kazakhstan</option>
                <option value="Kenya">Kenya</option>
                <option value="Kiribati">Kiribati</option>
                <option value="Korea, Democratic People's Republic of">
                  Korea, Democratic People's Republic of
                </option>
                <option value="Korea, Republic of">Korea, Republic of</option>
                <option value="Kosovo">Kosovo</option>
                <option value="Kuwait">Kuwait</option>
                <option value="Kyrgyzstan">Kyrgyzstan</option>
                <option value="Lao People's Democratic Republic">
                  Lao People's Democratic Republic
                </option>
                <option value="Latvia">Latvia</option>
                <option value="Lebanon">Lebanon</option>
                <option value="Lesotho">Lesotho</option>
                <option value="Liberia">Liberia</option>
                <option value="Libyan Arab Jamahiriya">
                  Libyan Arab Jamahiriya
                </option>
                <option value="Liechtenstein">Liechtenstein</option>
                <option value="Lithuania">Lithuania</option>
                <option value="Luxembourg">Luxembourg</option>
                <option value="Macao">Macao</option>
                <option value="Macedonia, the Former Yugoslav Republic of">
                  Macedonia, the Former Yugoslav Republic of
                </option>
                <option value="Madagascar">Madagascar</option>
                <option value="Malawi">Malawi</option>
                <option value="Malaysia">Malaysia</option>
                <option value="Maldives">Maldives</option>
                <option value="Mali">Mali</option>
                <option value="Malta">Malta</option>
                <option value="Marshall Islands">Marshall Islands</option>
                <option value="Martinique">Martinique</option>
                <option value="Mauritania">Mauritania</option>
                <option value="Mauritius">Mauritius</option>
                <option value="Mayotte">Mayotte</option>
                <option value="Mexico">Mexico</option>
                <option value="Micronesia, Federated States of">
                  Micronesia, Federated States of
                </option>
                <option value="Moldova, Republic of">
                  Moldova, Republic of
                </option>
                <option value="Monaco">Monaco</option>
                <option value="Mongolia">Mongolia</option>
                <option value="Montenegro">Montenegro</option>
                <option value="Montserrat">Montserrat</option>
                <option value="Morocco">Morocco</option>
                <option value="Mozambique">Mozambique</option>
                <option value="Myanmar">Myanmar</option>
                <option value="Namibia">Namibia</option>
                <option value="Nauru">Nauru</option>
                <option value="Nepal">Nepal</option>
                <option value="Netherlands">Netherlands</option>
                <option value="Netherlands Antilles">
                  Netherlands Antilles
                </option>
                <option value="New Caledonia">New Caledonia</option>
                <option value="New Zealand">New Zealand</option>
                <option value="Nicaragua">Nicaragua</option>
                <option value="Niger">Niger</option>
                <option value="Nigeria">Nigeria</option>
                <option value="Niue">Niue</option>
                <option value="Norfolk Island">Norfolk Island</option>
                <option value="Northern Mariana Islands">
                  Northern Mariana Islands
                </option>
                <option value="Norway">Norway</option>
                <option value="Oman">Oman</option>
                <option value="Pakistan">Pakistan</option>
                <option value="Palau">Palau</option>
                <option value="Palestinian Territory, Occupied">
                  Palestinian Territory, Occupied
                </option>
                <option value="Panama">Panama</option>
                <option value="Papua New Guinea">Papua New Guinea</option>
                <option value="Paraguay">Paraguay</option>
                <option value="Peru">Peru</option>
                <option value="Philippines">Philippines</option>
                <option value="Pitcairn">Pitcairn</option>
                <option value="Poland">Poland</option>
                <option value="Portugal">Portugal</option>
                <option value="Puerto Rico">Puerto Rico</option>
                <option value="Qatar">Qatar</option>
                <option value="Reunion">Reunion</option>
                <option value="Romania">Romania</option>
                <option value="Russian Federation">Russian Federation</option>
                <option value="Rwanda">Rwanda</option>
                <option value="Saint Barthelemy">Saint Barthelemy</option>
                <option value="Saint Helena">Saint Helena</option>
                <option value="Saint Kitts and Nevis">
                  Saint Kitts and Nevis
                </option>
                <option value="Saint Lucia">Saint Lucia</option>
                <option value="Saint Martin">Saint Martin</option>
                <option value="Saint Pierre and Miquelon">
                  Saint Pierre and Miquelon
                </option>
                <option value="Saint Vincent and the Grenadines">
                  Saint Vincent and the Grenadines
                </option>
                <option value="Samoa">Samoa</option>
                <option value="San Marino">San Marino</option>
                <option value="Sao Tome and Principe">
                  Sao Tome and Principe
                </option>
                <option value="Saudi Arabia">Saudi Arabia</option>
                <option value="Senegal">Senegal</option>
                <option value="Serbia">Serbia</option>
                <option value="Serbia and Montenegro">
                  Serbia and Montenegro
                </option>
                <option value="Seychelles">Seychelles</option>
                <option value="Sierra Leone">Sierra Leone</option>
                <option value="Singapore">Singapore</option>
                <option value="Sint Maarten">Sint Maarten</option>
                <option value="Slovakia">Slovakia</option>
                <option value="Slovenia">Slovenia</option>
                <option value="Solomon Islands">Solomon Islands</option>
                <option value="Somalia">Somalia</option>
                <option value="South Africa">South Africa</option>
                <option value="South Georgia and the South Sandwich Islands">
                  South Georgia and the South Sandwich Islands
                </option>
                <option value="South Sudan">South Sudan</option>
                <option value="Spain">Spain</option>
                <option value="Sri Lanka">Sri Lanka</option>
                <option value="Sudan">Sudan</option>
                <option value="Suriname">Suriname</option>
                <option value="Svalbard and Jan Mayen">
                  Svalbard and Jan Mayen
                </option>
                <option value="Swaziland">Swaziland</option>
                <option value="Sweden">Sweden</option>
                <option value="Switzerland">Switzerland</option>
                <option value="Syrian Arab Republic">
                  Syrian Arab Republic
                </option>
                <option value="Taiwan, Province of China">
                  Taiwan, Province of China
                </option>
                <option value="Tajikistan">Tajikistan</option>
                <option value="Tanzania, United Republic of">
                  Tanzania, United Republic of
                </option>
                <option value="Thailand">Thailand</option>
                <option value="Timor-Leste">Timor-Leste</option>
                <option value="Togo">Togo</option>
                <option value="Tokelau">Tokelau</option>
                <option value="Tonga">Tonga</option>
                <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                <option value="Tunisia">Tunisia</option>
                <option value="Turkey">Turkey</option>
                <option value="Turkmenistan">Turkmenistan</option>
                <option value="Turks and Caicos Islands">
                  Turks and Caicos Islands
                </option>
                <option value="Tuvalu">Tuvalu</option>
                <option value="Uganda">Uganda</option>
                <option value="Ukraine">Ukraine</option>
                <option value="United Arab Emirates">
                  United Arab Emirates
                </option>
                <option value="United Kingdom">United Kingdom</option>
                <option value="United States">United States</option>
                <option value="United States Minor Outlying Islands">
                  United States Minor Outlying Islands
                </option>
                <option value="Uruguay">Uruguay</option>
                <option value="Uzbekistan">Uzbekistan</option>
                <option value="Vanuatu">Vanuatu</option>
                <option value="Venezuela">Venezuela</option>
                <option value="Viet Nam">Viet Nam</option>
                <option value="Virgin Islands, British">
                  Virgin Islands, British
                </option>
                <option value="Virgin Islands, U.s.">
                  Virgin Islands, U.s.
                </option>
                <option value="Wallis and Futuna">Wallis and Futuna</option>
                <option value="Western Sahara">Western Sahara</option>
                <option value="Yemen">Yemen</option>
                <option value="Zambia">Zambia</option>
                <option value="Zimbabwe">Zimbabwe</option>
              </select>
              <label for="" class="control-label">Phone Number</label>

              <input
                type="tel"
                class="form-control"
                v-model="form.phone_no"
                pattern="^07[0-9]{8}"
              />
              <label for="" class="control-label">Message/Comment</label>
              <textarea
                name=""
                id=""
                rows="3"
                class="form-control"
                v-model="form.message"
                required
              ></textarea>

              <div class="text-right">
                <button
                  class="btn btn-outline-primary mx-2"
                  type="button"
                  @click="cancelReq"
                >
                  Cancel
                </button>
                <button class="btn btn-primary" type="submit">
                  Submit Request
                </button>
              </div>
            </div>
          </form>
        </b-modal>
      </div>
    </div>

    <div class="info-ui"></div>
  </div>
</template>

<script>
import { login, sso_login, request_demo,sso_auth } from "./actions.js";
import ForgotPassword from "./ForgotPassword.vue";
import VueCountdown from "@chenfengyuan/vue-countdown";

export default {
  components: { ForgotPassword, VueCountdown },
  data() {
    return {
      username: "",
      password: "",
      unauthorized: false,
      retrytime: 0,
      isAuthing: false,
      form: {
        name: "",
        organization: "",
        email: "",
        country: "",
        phone_no: "",
        message: "",
      },
    };
  },
  mounted() {
    if (this.$cookies.isKey("token")) {
      this.sso_login(this.$cookies.get("token"));
    }
    const auth_code = this.$route.query["auth_code"];
    if(auth_code){
      this.sso_auth(auth_code);
    }
  },
  methods: {
    login,
    sso_login,
    sso_auth,
    forgot_password() {
      this.$bvModal.show("forgot_password");
    },

    requestDemo() {
      this.$bvModal.show("request-demo");
    },
    requestForDemo() {
      request_demo(this.form)
        .then((data) => {
          this.$bvModal.hide("request-demo");
          this.$bvToast.toast(data, {
            title: "Notification",
            variant: "success",
            autoHideDelay: 5000,
          });
        })
        .catch((e) => {
          if (e.response.status == 422) {
            this.$bvToast.toast("Invalid form data", {
              title: "Notification",
              variant: "danger",
              autoHideDelay: 5000,
            });
          }
        });
    },
    cancelReq() {
      this.form = {
        name: "",
        organization: "",
        email: "",
        country: "",
        phone_no: "",
        message: "",
      };
      this.$bvModal.hide("request-demo");
    },
    // sso_logIn() {
    //   const sso_client = process.env.VUE_APP_SSO;
    //   const sso_id = process.env.VUE_APP_SSO_ID;
    //   const sso_callback = process.env.VUE_APP_SSO_CALLBACK;
    //   const url = new URL(sso_client);
    //   url.search = new URLSearchParams({
    //     clientId: sso_id,
    //     request_type: "code",
    //     callback:sso_callback
    //   });
    //   window.location.href = url;
    // },
  },
};
</script>

<style  scoped>
.bg-faded {
  background-color: #d4d4d4;
}
.login-form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  padding: 24px;
  width: 100%;
  max-width: 440px;
}
.login-form input {
  border: none;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.05);
}
.login-form input:focus {
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
}
.app-main {
  overflow-y: visible;
}
.app-main {
  padding: 0;
  /* background-color: red; */
}

.login-ui {
  position: fixed;
  top: 0 !important;
  left: 0;
  right: 0;
  bottom: 0;
}

.pt-nav {
  margin: 0 !important;
}
.full-height {
  height: 100vh;
  position: relative;
}
.link {
  cursor: pointer;
  font-weight: 600;
}

.bottom {
  position: absolute;
  bottom: 32px;
  left: 0;
  right: 0;
}
.h-menu a {
  display: inline-block;
  padding: 0 8px;
  border-right: 1px solid #aaa;
  color: #999 !important;
  font-weight: bold;
}
.h-menu a:hover {
  color: #3f7bb6 !important;
}

.h-menu a:last-child {
  border-right: none;
}

.top-left {
  background: #fff;
  padding: 32px;
}
.top-left .content {
  position: relative;
  top: 60px;
  text-align: center;
}
h1 {
  font-size: 18pt;
  font-weight: bold;
}

.text-large {
  font-size: 16pt;
  font-weight: 300;
}

.control-label {
  font-weight: bold;
  margin-bottom: 2px;
  font-size: 14px;
}
.control-label:after {
  content: " *";
  color: red;
}
</style>

<style >
#request-demo.modal .modal-content {
  border: none !important;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.1);
}

#request-demo.modal .modal-body {
  padding: 0 !important;
}

#request-demo .form-control {
  margin-bottom: 8px;
}
</style>
