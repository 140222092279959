import moment from "moment";

const PARTNER_BASE = process.env.VUE_APP_BACKEND + "/partners";
const FARMER_BASE = PARTNER_BASE + "/farmers";
const PRODUCE_BASE = PARTNER_BASE + "/produce";
const TARGET_BASE = PARTNER_BASE+"/goals";
const ADVANCES_BASE = process.env.VUE_APP_BACKEND + "/advances"
const PRODUCE_ADVANCES = PARTNER_BASE + "/advances";
const PRODUCE_INPUTS = PARTNER_BASE + "/inputs";
const PRODUCE_RANGE = 5;
let months = moment.monthsShort();
const SEASONS = [months.slice(0, 7), months.slice(7, 12)]
export { PARTNER_BASE, FARMER_BASE, PRODUCE_BASE, PRODUCE_RANGE, ADVANCES_BASE, SEASONS, PRODUCE_ADVANCES, PRODUCE_INPUTS,TARGET_BASE }
