const Reports = () => import("./Reports.vue");
const Produce = () => import("./subroutes/produce/Produce.vue")
const FarmInputs = () => import("./subroutes/farm_inputs/FarmInputs.vue")
const Training = () => import("./subroutes/training/Training.vue")
const Sales = () => import("./subroutes/sales/Sales.vue")

const moduleRoute = {
    path: "/reports",
    component: Reports,
    children:[
        {
            path:"",
            name:"produce",
            component:Produce
        },
        {
            path:"farm_inputs",
            name:"farm_inputs",
            component:FarmInputs
        },
        {
            path:"training",
            name:"training",
            component:Training
        },
        {
            path:"sales",
            name:"sales",
            component:Sales
        }
    ]
};
export default router=>{
    router.addRoutes([moduleRoute]);
}