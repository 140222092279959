
const FarmGroups = () => import("./FarmerGroups.vue");
const ListGroup = () => import("./ListGroup.vue");
const FarmGroup = () => import("./farmer_group/FarmerGroup.vue");
const router = {
    path: "farmer_groups",
    component: FarmGroups,
    children:[
        {
            path:"",
            component:ListGroup,
        },
        {
            path:":group_id",
            name:"farm_group",
            component:FarmGroup
        }
    ]
};
export default router