// eslint-disable-next-line no-unused-vars
import { AxiosInstance } from "axios";
import {
PARTNER_BASE,
FARMER_BASE,
PRODUCE_BASE,
PRODUCE_RANGE,
ADVANCES_BASE
} from "./constants.js";
import {add_pid} from "../utils.js"
import {states} from "@/components/loaders"

function Loader(){
    this.status = states.LOADING;
    this.active = false;
    this.fail = ()=>{
        this.status = states.FAIL;
    }
}
function cardStat(){
    Loader.call(this);
    this.qty = 0;
    /**
     * 
     * @param {string} qty 
     */
    this.done = (qty)=>{
        this.status = states.DONE;
        this.qty = qty;
        this.active = true;
    }
    this.reload = ()=>{
        this.status = states.LOADING;
        this.qty=0;
        this.active = false;
    }
}
cardStat.prototype = Object.create(Loader.prototype);
cardStat.prototype.constructor = cardStat;

function Pricing(){
    Loader.call(this);
    this.prices = [];
    /**
     * 
     * @param {Array} prices 
     */
    this.done = (prices)=>{
        this.status = states.DONE;
        this.prices = prices;
        this.active = true;
    }
    this.reload = ()=>{
        this.status = states.LOADING;
        this.prices = [];
        this.active = false;
    }
}
Pricing.prototype = Object.create(Loader.prototype);
Pricing.prototype.constructor = Pricing;
function Targets(){
    Loader.call(this);
    this.targets = [];
    /**
     * 
     * @param {Array} targets 
     */
    this.done = (targets)=>{
        this.targets = targets;
        this.active = true;
        this.status = states.DONE;
    }
    this.reload = ()=>{
        this.status = states.LOADING;
        this.active = false;
    }
}
Targets.prototype = Object.create(Loader.prototype);
Targets.prototype.constructor = Targets;
/**
 * @param {number} project_id
 * @param {AxiosInstance} axiosApiInstance
 * @param {string[]} dates
 * @returns {Promise<string>}
 */ 
async function get_farmer_count(project_id,axiosApiInstance,dates) {
    const params = new URLSearchParams();
    if(dates.length){
        params.append("dates",dates[0]);
        params.append("dates",dates[1]);
    }
    const res = await axiosApiInstance.get(add_pid(FARMER_BASE,project_id)+"/count",{params});
    return res.data.count;
}
/**
 * @param {number} project_id
 * @param {AxiosInstance} axiosApiInstance 
 * @param {string[]} dates
 * @returns {Promise<string>}
 */ 
async function get_va_count(project_id,axiosApiInstance,dates) {
    const params = new URLSearchParams();
    if(dates.length){
        params.append("dates",dates[0]);
        params.append("dates",dates[1]);
    }
    const res = await axiosApiInstance.get(add_pid(PARTNER_BASE,project_id)+"/vas/count",{params});
    return res.data.count;
}
/**
 * @param {number} project_id
 * @param {AxiosInstance} axiosApiInstance
 * @param {string[]} dates
 * @returns {Promise<string>}
 */ 
async function get_total_acreage(project_id,axiosApiInstance,dates) {
    const params = new URLSearchParams();
    if(dates.length){
        params.append("dates",dates[0]);
        params.append("dates",dates[1]);
    }
    const res = await axiosApiInstance.get(add_pid(FARMER_BASE,project_id)+"/total_acreage",{params});
    return res.data;
}
/**
 * @param {number} project_id
 * @param {AxiosInstance} axiosApiInstance
 */ 
function get_product_count(project_id,axiosApiInstance) {
    axiosApiInstance.get(add_pid(PRODUCE_BASE,project_id) + "/count").then(res => {
        this.product_count = res.data
    });
}
/**
 * @param {number} project_id
 * @param {AxiosInstance} axiosApiInstance
 * @param {string[]} dates
 * @returns {Promise<string>} 
 */ 
async function get_produce_available(project_id,axiosApiInstance,dates) {
    const params = new URLSearchParams();
    if(dates.length){
        params.append("dates",dates[0]);
        params.append("dates",dates[1]);
    }
    const res = await axiosApiInstance.get(add_pid(PRODUCE_BASE,project_id)+"/available",{params});
    return res.data;
}
/**
 * @param {number} project_id
 * @param {AxiosInstance} axiosApiInstance
 * @param {string[]} dates
 * @returns {Promise<string>}
 */ 
async function get_payments_pending(project_id,axiosApiInstance,dates) {
    const params = new URLSearchParams();
    if(dates.length){
        params.append("dates",dates[0]);
        params.append("dates",dates[1]);
    }
    const res = await axiosApiInstance.get(add_pid(PRODUCE_BASE,project_id) + "/payments_pending",{params});
    return res.data;
}
/**
 * @param {number} project_id
 * @param {number} start
 * @param {AxiosInstance} axiosApiInstance
 * @returns {Promise<Array>}
 */ 
async function get_produce_prices(project_id,start,axiosApiInstance){
    const res = await axiosApiInstance.get(add_pid(PRODUCE_BASE,project_id)+"/prices",{params:{start,prange:PRODUCE_RANGE}});
    return res.data;
}
/**
 * 
 * @param {number} project_id
 * @param {AxiosInstance} axiosApiInstance
 * @param {string[]} dates
 * @returns {Promise<string>}
 */
async function get_advances_due(project_id,axiosApiInstance,dates){
    const params = new URLSearchParams();
    if(dates.length){
        params.append("dates",dates[0]);
        params.append("dates",dates[1]);
    }
    params.set("project_id",project_id);
    const res = await axiosApiInstance.get(ADVANCES_BASE+"/due",{params});
    return res.data;
}
/**
 * @param {number} project_id
 * @param {AxiosInstance} axiosApiInstance
 * @returns {Promise<Array>}
 */ 
async function get_produce_targets(project_id,axiosApiInstance){
    /**@constant {{data:Array<{actual:number,target:number}>}} res */
    const res = await axiosApiInstance.get(add_pid(PRODUCE_BASE,project_id)+"/targets");
    return res.data.map(el=>{
        el.actual = el.actual/1000;
        el.target = el.target/1000;
        return el;
    });
}
/**
 * @param {number} project_id
 * @param {AxiosInstance} axiosApiInstance
 */
function get_produce_yrange(project_id,axiosApiInstance){
    axiosApiInstance.get(add_pid(PRODUCE_BASE,project_id)+"/year_range").then(res=>{
        this.yrange = res.data;
    });
}

export {
    get_farmer_count,
    get_va_count,
    get_total_acreage,
    get_product_count,
    get_produce_available, 
    get_payments_pending,
    get_produce_prices,
    get_advances_due,
    get_produce_targets,
    get_produce_yrange,
    cardStat,
    Pricing,
    Targets,
    Loader
}
