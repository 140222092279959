const Suppliers = () => import("./Suppliers.vue");
const Agents = () => import("./subroutes/agents/Agents.vue");
import * as FarmGroups from "./subroutes/farmer_groups/index.js"
import * as Buyers from "./subroutes/buyers/index";
const Farmers = () => import("./subroutes/farmers/Farmers.vue");
const Training = () => import("./subroutes/training/Training.vue");
const AgentProfile = () => import("./subroutes/agents/agent_profile/AgentProfile.vue");
const moduleRoute = {
    path: "/suppliers",
    component:Suppliers,
    children:[
        {
            path:'agents',
            name:'agents',
            component:Agents
        },
        FarmGroups.default,
        Buyers.default,
        {
            path:'farmers',
            name:'farmers',
            component:Farmers,
        },
        {
            path:'training',
            name:'training',
            component:Training
        },
        {
            path: 'agent-profile/:id',
            component: AgentProfile
        }
    ]
};

export default router=>{
    router.addRoutes([moduleRoute]);
}
