const FarmInput = () => import("./FarmInput.vue");
const InputSales = () => import("./subroutes/input_sales/InputSales.vue");
const Advances = () => import("./subroutes/advances/Advances.vue");

const moduleRoute = {
    path: "/farm_inputs",
    component:FarmInput,
    children:[
        {
            path:'input_sales',
            name:'input_sales',
            component:InputSales
        },
        {
            path:'advances',
            name:'advances',
            component:Advances
        }
    ]
        
    
    };
export default router=>{
    router.addRoutes([moduleRoute]);
}
