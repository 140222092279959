import { axiosApiInstance } from "@/plugins/axios.js";
import { Loader } from "../dashboard/actions";
import {states} from "@/components/loaders";
import moment from "moment";

const PROJECT_BASE = process.env.VUE_APP_BACKEND + "/projects"
async function fetch_projects(){
    const res = await axiosApiInstance({url:PROJECT_BASE+"/"});
    return res.data;
}
function ProjectsLoader(){
    Loader.call(this);
    this.done = ()=>{
        this.status = states.DONE;
        this.active = true;
    }
}
/**
 * @param {{name:string,created_at:string,description:string}} project
 */
function modify_date(project){
    return {...project,created_at:moment(project.created_at).format("YYYY-MM-DD")}
}
/**
 * @param {{name:string,created_at:string,description:string}} project
 */
function create_project(project){
    const info = modify_date(project);
    return axiosApiInstance.post(PROJECT_BASE+"/",info);
}
/**
 * @param {{id:number,name:string}} project
 */
function rename_project(project){
    axiosApiInstance.post(
        PROJECT_BASE+`/P-${project.id}`+"/rename",
        {name:project.name}
    ).then(()=>{
        this.$vToastify.success("project name changed");
        this.get_projects();
    });
}
/**
 * @param {{id:number,name:string}} project
 */
function delete_project(project){
    axiosApiInstance.delete(
        PROJECT_BASE +`/P-${project.id}`
    ).then(()=>{
        this.projects = this.projects.filter(el=>{
            return el.id!=project.id
        });
    });
}
/**
 * 
 * @param {string} name 
 */
function check_name(name){
    axiosApiInstance({
        url:PROJECT_BASE+"/check_name",
        params:{name}
    }).then(res=>{
        /**
         * @const {{name_exists:boolean}} info
         */
        const info = res.data;
        this.name_exists = info.name_exists;
    });
}

function select_projects(){
    fetch_projects().then((res)=>{
        this.projects = res.data.map(el=>{
            el.name_exists = false;
            el.check_name = check_name;
            return el;
        });
    })
}

export {fetch_projects,create_project,rename_project,delete_project,check_name,select_projects,ProjectsLoader}