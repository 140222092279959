
const Buyers = () => import("./Buyers.vue");
const ListBuyers = () => import("./subroutes/BuyersList.vue");
import * as Buyer from "./subroutes/buyer/index";
const router = {
    path: "buyers",
    component: Buyers,
    children:[
        {
            path:"",
            component:ListBuyers,
        },
        Buyer.default,
    ]
};
export default router