const mixpanel = require("mixpanel-browser");
function Analytics(){
    this.mixpanel = mixpanel;
    this.mixpanel.init(process.env.VUE_APP_MIXPANEL_TOKEN);
    /**
     * 
     * @param {string} event_name 
     * @param {{element:string}} event_meta 
     */
    this.track = (event_name,event_meta)=>{
        if(process.env.VUE_APP_ENV==='production'){
            this.mixpanel.track(event_name,event_meta);
        }
    }
}
const analytics = new Analytics();
export default analytics;