export const states = {LOADING:'loading',FAIL:'fail',DONE:'done'}
export const loadMixin = {
    props:{
        status:String
    },
    data(){
        return {
            states
        }
    }
}
function loader(){
    this.status = states.LOADING;
    this.active = false;
    this.done = ()=>{
        this.status = states.DONE;
        this.active = true;
    }
    this.reload = ()=>{
        this.active = false;
        this.status = states.LOADING;
    }
    this.fail = ()=>{
        this.active = false;
        this.status = states.FAIL;
    }
}
export {loader};