import moment from "moment";



function formatDate(datestr){
    if(!datestr)return null;
    return moment(datestr).format("YYYY-MM-DD");
}
const dateMod = {
    namespaced:true,
    state:()=>({
        sdate:()=>localStorage.getItem("offtaker.sdate"),
        edate:()=>localStorage.getItem("offtaker.edate")
    }),
    mutations:{
        setRange(_,{sdate,edate}){
            localStorage.setItem("offtaker.sdate",sdate);
            localStorage.setItem("offtaker.edate",edate);
        }
    },
    getters:{
        dateRange:(state)=>()=>{
            return [formatDate(state.sdate()),formatDate(state.edate())];
        }
    },
    actions:{
        filterDate:({commit},{sdate,edate})=>{
            commit("setRange",{sdate,edate});
        }
    }
}
export default dateMod;