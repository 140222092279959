<template>
  <div class="position-relative">
    <div class="pos-50">
      <dot-flash loaderClass="loader-primary" :status="ldr.status" ></dot-flash>
    </div>
    <b-nav vertical fill>
      <b-nav-item
        v-show="ldr.active"
        v-for="project in projects"
        :key="project.id"
        :active="projectId == project.id"
        :class="{ 'alert-primary rounded': projectId == project.id }"
        link-classes="deco-none"
        @click="()=>{projectId=project}"
      >
        <b-icon icon="circle"></b-icon> &nbsp;{{ project.name }}
      </b-nav-item>
    </b-nav>
    <div class="d-flex mt-1 px-0">
        <div class="col-6 text-center card-bdr py-2">
            <b-link @click="change_screen('Options')">
                <b-icon icon="gear"></b-icon>
                &nbsp;
                Options
            </b-link>
        </div>
        <div class="col-6 text-center card-bdr py-2">
            <b-link @click="change_screen('New Scheme')">
                <b-icon icon="plus"></b-icon>
                &nbsp;
                Add a Scheme
            </b-link>
        </div>
    </div>
  </div>
</template>
<style scoped>
.pos-50{
  position: absolute;
  left:200px;
  z-index: inherit;
}
</style>
<script>
import { fetch_projects,ProjectsLoader } from "./actions.js";
import DotFlash from "@/components/loaders/DotFlash.vue";
import {project_mixin} from "./utils.js"

export default {
  components:{DotFlash},
  computed: {
    projectId: {
      get() {
        return this.$store.state.project_id;
      },
      set(value) {
        this.$store.commit("change_pid", value);
        this.$nextTick(()=>{
            this.$router.go(0);
        });
      },
    },
  },
  mixins:[project_mixin],
  mounted() {
    this.getProjects();
  },
  data() {
    return {
      projects: [],
      ldr:new ProjectsLoader()
    };
  },
  methods: {
    getProjects(){
      fetch_projects().then((projects)=>{
        this.projects = projects;
        this.ldr.done();
      }).catch(()=>{this.ldr.fail()})
    }
  },
};
</script>