import Vue from "vue";
import "./plugins/fontawesome";
import "./plugins/bootstrap-vue";
import "./assets/css/dist/main.css";
import "./assets/css/main.scss";
import "./assets/unicons/css/line.css";

import "vue2-datepicker/index.css";
import VueBootstrapTypeahead from "vue-bootstrap-typeahead";
import FunctionalCalendar from "vue-functional-calendar";
import VueCookies from "vue-cookies";
import Print from "vue-print-nb";
import JsonExcel from "vue-json-excel";
import VueToastify from "vue-toastify";
import App from "./App.vue";
import router from "./router";
import dashboardModule from "./modules/dashboard";
import produceModule from "./modules/produce";
import farmModule from "./modules/farm_input";
import paymentsModule from "./modules/payments";
import suppliersModule from "./modules/suppliers";
import producesalesModule from "./modules/produce_sales";
import reportsModule from "./modules/reports";
import marketModule from "./modules/market_place";
import settingsModule from "./modules/settings";
import authModule from "./modules/auth";
import trainingModule from "./modules/training";
import store from "./store.js";
import VuejsDialog from 'vuejs-dialog';



// include the default style
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
 
// Tell Vue to install the plugin.
Vue.use(VuejsDialog);

import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);

// Add google maps
// import GmapVue from 'gmap-vue';
// Vue.use(GmapVue,{load:{key:process.env.VUE_APP_GMAP_KEY}});

// Register it globally
// Add openlayers
import VueLayers from 'vuelayers';
import 'vuelayers/dist/vuelayers.css';
Vue.use(VueLayers);

Vue.config.productionTip = false;
Vue.component("vue-bootstrap-typeahead", VueBootstrapTypeahead);
Vue.component("downloadExcel", JsonExcel);
Vue.use(VueToastify);
Vue.use(Print);
Vue.use(VueCookies);
Vue.use(FunctionalCalendar, {
  dayNames: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
});


import analytics from "./web-analytics.js"
Vue.prototype.$analytics = analytics;

import { registerModules } from "./register-modules";
registerModules({
  dashboard: dashboardModule,
  produce: produceModule,
  farm: farmModule,
  payments: paymentsModule,
  suppliers: suppliersModule,
  producesales: producesalesModule,
  reports: reportsModule,
  settings: settingsModule,
  auth: authModule,
  market:marketModule,
  training: trainingModule,
});
export const bus = new Vue();

import vanalytics from "./vue-analytics";
Vue.directive('analytics',vanalytics);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
