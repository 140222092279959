import { BLink } from 'bootstrap-vue'

/**
 * @typedef {Object} VaDetails
 * @property {string} bulk_record_id
 * @property {string} vaId
 * @property {string} va_name
 * @property {number} quantity
 * @property {string} value_chain
 */
/**
 * @typedef {Object} BaseTransaction
 * @property {string} donor
 * @property {string} recep
 * @property {string} date
 * @property {number} amount
 * @property {{type:string}} meta
 */
/**
 */
class VaPayment{
    /**
     * 
     * @param {{details:VaDetails}} data 
     */
    constructor(data){
        this.type = "produce:payment:va"
        this.payment = data.details;
    }
    display(h){
        const va_name = this.payment.va_name;
        const vaId = this.payment.vaId;

        return h(BLink,{
            props:{to:{path:"/suppliers/agent-profile/"+vaId.replace(/\//g,"-")}}
        },va_name);
    }

}

class Transaction{
    /**
     * 
     * @param {BaseTransaction} data 
     */
    constructor(data){
        this.donor = data.donor;
        this.recep = data.recep;
        this.amount = data.amount;
        this.date = data.date;
        switch(data.meta.type){
            case "produce:payment:va":
                this.meta = new VaPayment(data.meta);
                break;
            default:
                this.meta = { type:data.meta.type,display:(h)=>{
                    let tmp = data.meta.type.replace(":"," ");
                    return h('span',tmp) 
                }}
        }
    }
    display(h){
        return this.meta.display(h);
    }
}
import Vue from "vue";
const TransactionDisplay = Vue.component("transaction-display",{
    props:{
        transaction:{
            type:Transaction,
            required:true
        }
    },
    render(h){
        return this.transaction.display(h)
    }
})
/**
 * 
 * @param {type:string} meta 
 */
function get_entity(meta){
    if(meta.type.includes("offtaker")){    
        return "Offtaker";
    }
    if(meta.type.includes("va")){
        return "VA" | meta.payment.va_name;
    }
    return "NA"
}

/**
 * 
 * @param {Transaction[]} transx
 * @param {string} wallet_id 
 */
function to_json(transx,wallet_id){
    const items = transx.map(el=>{
      let tmp = {
          date:el.date,
          entity:get_entity(el.meta),
          credit:wallet_id==el.recep?el.amount:"",
          debit:wallet_id==el.donor?el.amount:""
      }
      return tmp;
    });
    return items;
}
export {Transaction,TransactionDisplay,to_json}