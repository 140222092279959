function can(action,resource){
    let uperms = localStorage.getItem("permissions");
    if (!uperms){
        return false;
    }
    let permissions = JSON.parse(uperms);
    
    if(!Object.keys(permissions).includes(resource)){
        return false;
    }
    if(!permissions[resource].includes(action)){
        return false;
    }
    return true;
}
export {can}