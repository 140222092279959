const Payments = () => import("./Payments.vue");
const PendingPayments= () => import("./subroutes/payments/Payments.vue");
const Schedules = () => import("./subroutes/schedules/Schedules.vue");
const Schedule = () => import("./subroutes/schedule/Schedule.vue");
const Transactions = () => import("./subroutes/transactions/Transactions.vue") 

const moduleRoute = {
    path: "/payments",
    component:Payments,
    children:[
        {
            path:'',
            name:'pending-payments',
            component:PendingPayments
        },
        {
            path:'schedule/:schedule_id',
            name:'schedule',
            component:Schedule
        },
        {
            name:'schedules',
            path:'schedules',
            component:Schedules
        },
        {
            name:'transactions',
            path:'transactions',
            component:Transactions,
        }
    ]

    };
export default router=>{
    router.addRoutes([moduleRoute]);
}
