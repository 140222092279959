import Vue from "vue";

import Vuex from "vuex";

Vue.use(Vuex);
import dateMod from "./components/date_picker/store";
import euro from "@/assets/uganda_new.json";

import buyerMod from "./modules/suppliers/subroutes/buyers/subroutes/buyer/store";

/**
 * @typedef {Object} Agent
 * @property {string} id
 * @property {Wallet} wallet
 * @property {string} name
 * @property {string} username
 * @property {phone_no} phone_no
 */

export default new Vuex.Store({
    modules:{dates:dateMod,buyera:buyerMod},
    state: {
        buyer:null,
        projects:[],
        /**@type {import("./modules/auth/actions").App[]} */
        apps:[],
        username:localStorage.getItem("username"),
        url_prefix:localStorage.getItem("url_prefix"),
        project_id:parseInt(localStorage.getItem("project_id")==null?"1":localStorage.getItem("project_id")),
        project_name: localStorage.getItem("project_name")==null? "General Account":localStorage.getItem("project_name"),
    },
    mutations:{
        /**
         * 
         * @param {{projects:[],project_id:1}} state 
         * @param {number} pid 
         */
        change_pid(state,project){
            state.project_id = project.id;
            localStorage.setItem("project_id",project.id);
            localStorage.setItem("project_name",project.name);
        },
        /**
         * 
         * @param {{projects:[],project_id:number,username:string}} state 
         * @param {string} username 
         */
        set_username(state,username){
            state.username = username
            localStorage.setItem("username",username);

        },
        /**
         * 
         * @param {{projects:[],project_id:number,username:string}} state 
         * @param {string} username 
         */
        set_url_prefix(state,buyer){
            let prefix = buyer?"/agent":"";
            localStorage.setItem("url_prefix",prefix);
            state.url_prefix=prefix;
        },
        /**
         * @param {{projects:[],project_id:number,username:string,buyer:Agent}} state
         * @param {Agent} agent 
         */
        save_agent(state,agent){
            state.buyer = agent;
        },
        /**
         * 
         * @param {{apps:App[]}} state 
         * @param {App[]} apps 
         */
        set_apps(state,apps){
            state.apps = apps;
        }
    },
    actions:{
        saveApps({commit},apps){
            commit('set_apps',apps)
            localStorage.setItem("apps",JSON.stringify(apps));
        },
        loadApps({commit}){
            const tapps = localStorage.getItem('apps') || '[]';
            const apps = JSON.parse(tapps);
            commit('set_apps',apps);
        }
    },
    getters:{
        /**
         * 
         * @returns {string[]}
         */
        all_districts(){
            return euro.features.map(el=>el.properties.name)
        },
        getApps:(state)=>{
            return state.apps;
        }
    }

});