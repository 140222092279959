<template>
    <b-modal id="reset-password" title="Reset Password" size="sm" hide-footer>
        <b-form @submit.prevent="changePassword">
            <b-form-group label="Current Password" :invalid-feedback="'Invalid current password'" :state="is_valid">
                <b-form-input type="password" v-model="current" required :state="is_valid"></b-form-input>
            </b-form-group>
            <b-form-group label="New Password">
                <b-form-input type="password" v-model="novel" required></b-form-input>
            </b-form-group>
            <b-form-group label="Confirm New Password">
                <b-form-input v-model="confirm" type="password" required></b-form-input>
            </b-form-group>
            <b-button type="submit" variant="primary"  class="w-100" 
            :disabled="(novel!=confirm)||(current==novel)" 
            v-analytics:click="['confirm_new_password',{element:'Dashboard'}]"
            >
                Submit
            </b-button>
        </b-form>
        <b-toast variant="success" id="password-changed">
            Password was successfully changed.
        </b-toast>
    </b-modal>
</template>
<script>
import {axiosApiInstance} from "@/plugins/axios.js"
export default {
    props:["username"],
    data(){
        return {
            current:"",
            novel:"",
            confirm:"",
            is_valid:true
        }
    },
    methods:{
        async validatePassword(){
            const res = await axiosApiInstance({
                url:process.env.VUE_APP_BACKEND + "/auth/validate_password",
                method:"post",
                params:{username:this.username,password:this.current}
            });
            this.is_valid = res.data.valid;
        },
        changePassword(){
            this.validatePassword().then(()=>{
                if(this.is_valid){
                    axiosApiInstance({
                        url:process.env.VUE_APP_BACKEND+"/auth/change_password",
                        method:"post",
                        params:{username:this.username,current:this.current,novel:this.novel}
                    }).then(()=>{
                        this.$bvToast.show("password-change");
                        setTimeout(()=>{
                            this.$bvModal.hide("reset-password");
                        },4000);
                    })
                }
            })
        }
    }
}
</script>