<template>
  <b-modal id="forgot_password" title="Password Recovery" hide-footer size="md" centered>
    <i>Please submit your registered email.</i>
    <b-form  class="mt-1" @submit.prevent="recover_password">
      <label class="sr-only" for="inline-form-input-email">Registered Email</label>
      <b-form-input
        id="inline-form-input-email"
        class="mb-2 mr-sm-2 mb-sm-0"
        v-model="email"
        type="email"
        placeholder="example@example.com"
        required
      ></b-form-input>
      <small>{{msg}}</small>
      <b-button variant="outline-primary" type="submit" class="w-100 mt-1">Submit</b-button>
    </b-form>

  </b-modal>
</template>
<script>
import * as Misc from "./misc.js"
export default {
    data(){
        return {
            email:"",
            msg:""
        }
    },
    methods:{
        recover_password(){
            Misc.recover_password(this.email,this);
        }
    }
};
</script>