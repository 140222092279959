<template>
  <div id="app" @click="hideMenu()">
    <div class="container-fluid- no-margin-left">
      <b-navbar class="shadow-sm top-nav" v-if="$route.path != '/login'">
        <b-navbar-brand>
          <button
            class="toggle-menu uil"
            :class="toggleMenu ? ' uil-times' : 'uil-bars'"
            @click="showMenu($event)"
          ></button>
          <img src="@/assets/logo.png" height="40px" />
          <b class="text-dark ml-2">Ezy</b>
          <b class="text-success">Produce</b>
        </b-navbar-brand>

        <b-navbar-nav class="ml-auto">
          <div class="dropdown b-dropdown m-md-2 btn-group">
            <button
              class="btn dropdown-toggle btn-light"
              v-b-modal.modal-1
              :disabled="!can('view', 'projects')"
            >
              {{ projectName }}
            </button>
          </div>
          <date-picker @DateChange="reload" />
        </b-navbar-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item v-show="false">
            <b-icon-bell></b-icon-bell>
          </b-nav-item>

          <!-- <b-nav-item-dropdown class="mt-2" v-show="can('view','apps')">
            <template #button-content>
              <b-icon-grid variant="primary"></b-icon-grid> Apps
            </template>
            <b-dropdown-item-button
              v-for="eapp in apps"
              :key="eapp.id"
              @click="launchApp(eapp.callback, eapp.id)"
              :disabled="!eapp.owned"
            >
              <b-icon-grid variant="primary"></b-icon-grid> &nbsp;
              {{ eapp.name }}
            </b-dropdown-item-button>
          </b-nav-item-dropdown> -->

          <b-nav-item-dropdown right>
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <i class="uil uil-user profile-icon"></i>
              <span>{{ username }}</span>
            </template>
            <b-dropdown-item
              @click="reset_password"
              v-analytics:click="['reset_password', { element: 'Dashboard' }]"
              ><i class="uil uil-lock"></i> Reset Password</b-dropdown-item
            >
            <b-dropdown-item @click="log_out"
              ><i class="uil uil-signout text-danger"></i>
              Logout</b-dropdown-item
            >
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-navbar>
      <div class="row m-0">
        <div class="col-auto p-0">
          <div class="menu-holder" :class="toggleMenu ? 'show' : ''">
            <b-nav
              vertical
              class="app-side bg-primary-dark"
              @click="showMenu($event)"
              v-if="$route.path != '/login'"
              style="padding-top: 24px"
            >
              <b-nav-item
                to="/"
                class=""
                :class="{ 'bg-info-1': $route.path == '/' }"
              >
                <span class="nav-icon dash-icon"></span>
                <span> Dashboard </span></b-nav-item
              >
              <b-nav-item
                to="/suppliers/farmers"
                :class="{ 'child-active': subIsActive('/suppliers') }"
              >
                <span class="nav-icon supplier-icon"></span>

                <span>
                  <!-- <img src="@/assets/suppliers.svg" class="d-inline-block mr-1 ml-2 nav-icon"> -->
                  Suppliers
                </span>
                <b-icon-chevron-down class="drop-arrow"></b-icon-chevron-down>

                <!-- <b-icon-chevron-down class="drop-icon" v-if="subIsActive('/suppliers')"></b-icon-chevron-down> -->
              </b-nav-item>
              <div class="nav-dropdown-menu" v-if="subIsActive('/suppliers')">
                <b-nav-item to="/suppliers/farmers">
                  <span class=""> Farmers </span>
                </b-nav-item>
                <b-nav-item to="/suppliers/farmer_groups">
                  <span class=""> Farmer Groups </span>
                </b-nav-item>
                <b-nav-item to="/suppliers/agents">
                  <span class=""> Agents </span>
                </b-nav-item>
                <b-nav-item to="/suppliers/buyers">
                  Buying Agents
                </b-nav-item>
              </div>

              <b-nav-item
                to="/farm_inputs/input_sales"
                :class="{ 'child-active': subIsActive('/farm_inputs') }"
                :disabled="!can('view', 'produce')"
              >
                <span class="nav-icon input-icon"></span>

                <span>
                  Farm Inputs
                  <!-- <b-icon-chevron-down class="drop-arrow" v-if="subIsActive('/farm_inputs')"></b-icon-chevron-down> -->
                </span>
                <b-icon-chevron-down class="drop-arrow"></b-icon-chevron-down>
              </b-nav-item>
              <div
                class="nav-dropdown-menu"
                :class="{ 'bg-info-1': subIsActive('/farm_inputs') }"
                v-if="subIsActive('/farm_inputs')"
              >
                <b-nav-item to="/farm_inputs/input_sales">
                  <span class=""> Input Sales</span>
                </b-nav-item>
                <b-nav-item to="/farm_inputs/advances">
                  <span class=""> Advances</span>
                </b-nav-item>
              </div>
              <b-nav-item
                to="/training"
                class=""
                :class="{ 'bg-info-1': $route.path == '/training' }"
                :disabled="!can('view', 'training')"
              >
                <span class="nav-icon training-icon"></span>

                <span> Training</span>
              </b-nav-item>
              <b-nav-item
                to="/produce"
                class=""
                :class="{ 'bg-info-1': $route.path == '/produce' }"
                :disabled="!can('view', 'produce')"
              >
                <span class="nav-icon produce-icon"></span>
                <span>
                  <!-- <img src="@/assets/produce.svg" class="mr-2 ml-1 d-inline-block nav-icon"> -->
                  Produce
                </span></b-nav-item
              >

              <b-nav-item
                to="/payments"
                class=""
                :class="{ 'child-active': subIsActive('/payments') }"
                v-if="can('view', 'payments')"
              >
                <span class="nav-icon payments-icon"></span>
                <span> Payments </span>
                <b-icon-chevron-down class="drop-arrow"></b-icon-chevron-down>
              </b-nav-item>
              <div class="nav-dropdown-menu" v-if="subIsActive('/payments')">
                <b-nav-item to="/payments">
                  <span class=""> Pending Payments </span>
                </b-nav-item>
                <b-nav-item to="/payments/schedules">
                  <span class=""> Payment Schedules </span>
                </b-nav-item>
                <b-nav-item to="/payments/transactions">
                  <span class=""> Payment Transactions </span>
                </b-nav-item>
              </div>

              <!-- 
              <b-nav-item class="pt-2 pb-1" to="/produce_sales" :class="{ 'bg-info-1': $route.path == '/produce_sales' }"><span
                     v-show="false">
                  <b-icon-person class="mr-2"></b-icon-person> Produce Sales
                </span></b-nav-item> -->
              <b-nav-item
                class=""
                to="/reports"
                :class="{ 'bg-info-1': $route.path.includes('/reports') }"
              >
                <span class="nav-icon reports-icon"></span>
                <span>
                  <!-- <b-icon-bar-chart-line class="mr-2"></b-icon-bar-chart-line> -->
                  Reports
                </span></b-nav-item
              >
              <b-nav-item
                class="py-1"
                to="/market_place"
                :class="{ 'bg-info-1': $route.path.includes('/market_place') }"
              >
                <span class="nav-icon store-icon"></span>
                <span class="text-light"> Market Place </span>
              </b-nav-item>
              <b-nav-item
                class=""
                to="/settings/farm_input"
                style="position: absolute; bottom: 16px; left: 0; right: 0"
                :class="{ 'bg-info-1': $route.path.includes('/settings') }"
                v-if="can('view', 'accounts') && can('modify', 'accounts')"
              >
                <span class="nav-icon settings-icon"></span>
                <span>
                  <!-- <b-icon-gear class="mr-1"></b-icon-gear> -->
                  Settings
                </span>
              </b-nav-item>
            </b-nav>
          </div>
        </div>
        <div class="col px-0">
          <div :key="mainkey" class="w-100">
            <router-view class="app-main pt-nav"> </router-view>
          </div>
        </div>
      </div>
    </div>
    <projects></projects>
    <reset-password :username="username"></reset-password>
  </div>
</template>

<style lang="scss">
@import "./assets/css/_variable.scss";
@import "./assets/css/breakpoints.scss";
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700&display=swap");

*.drop-arrow {
  font-size: 9px !important;
  height: 12px;
  display: block;
  margin-left: auto !important;
  right: 16px;
  position: absolute;
  top: 50%;
  transition-duration: 0.3s;
  transform: translateY(-40%);
  //  -webkit-transform: translateY(-50%);
  //  -moz-transform: translateY(-50%);
  right: 16px;
}
.profile-icon {
  height: 40px;
  width: 40px;
  border-radius: 40px;
  margin-right: 16px;
  display: inline-block;
  text-align: center;
  line-height: 40px;
  border: 1px solid $color-primary;
}

.top-nav {
  .dropdown-menu {
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
    border: none !important;
    padding: 8px;
    a {
      color: #2c3e50 !important;
      font-weight: 600;
      height: 40px;
      line-height: 40px;
      padding: 0 16px;
      border-radius: 8px;
      i {
        margin-right: 16px;
      }
    }
  }
}
.nav-icon {
  width: 24px;
  height: 24px;
  background-position: center;
  background-size: 85%;
  background-repeat: no-repeat;
  margin: 0 16px;
  display: inline-block;
  vertical-align: middle;
  // background-color: $color-accent;
  // border-radius: 40px;
  opacity: 0.8;

  &.dash-icon {
    background-image: url(~@/assets/icons/icons8-dashboard-50.png);
  }

  &.supplier-icon {
    background-image: url(~@/assets/icons/icons8-farmer-50.png);
  }

  &.input-icon {
    // background-image:url(~@/assets/dashboard.svg);
    background-image: url(~@/assets/icons/input.png);
  }

  &.training-icon {
    // background-image:url(~@/assets/dashboard.svg);
    background-image: url(~@/assets/icons/trainings.png);
  }

  &.produce-icon {
    // background-image:url(~@/assets/dashboard.svg);
    background-image: url(~@/assets/icons/produce.png);
  }

  &.payments-icon {
    // background-image:url(~@/assets/dashboard.svg);
    background-image: url(~@/assets/icons/payments.png);
  }

  &.reports-icon {
    // background-image:url(~@/assets/dashboard.svg);
    background-image: url(~@/assets/icons/reports.png);
  }
  &.market-icon {
    // background-image:url(~@/assets/dashboard.svg);
    background-image: url(~@/assets/icons/market.png);
  }

  &.settings-icon {
    // background-image:url(~@/assets/dashboard.svg);
    background-image: url(~@/assets/icons/settings.png);
  }
  &.store-icon {
    background-image: url(~@/assets/icons/market.png);
  }
}
#app {
  font-family: "Nunito Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.top-nav {
  position: fixed;
  z-index: 20;
  left: 0;
  right: 0;
  height: $navbar-height;
  background-color: #fff;
}

.menu-holder {
  width: 200px;
  @include media("<=large") {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 1px;
    z-index: 40;

    .app-side {
      left: -200px;
      box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
      transition-duration: 0.3s;
      -webkit-transition-duration: 0.3s;
      transition-timing-function: ease-in;
    }

    &.show {
      right: 0;
      width: auto;
      // background: red;
      .app-side {
        left: 0px !important;
      }
    }
  }
}

.toggle-menu {
  display: none;
  transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  &.uil-times {
    transform: rotate(90deg);
  }
  @include media("<=large") {
    display: inline-block;
    background-color: #fff;
    border-radius: 8px;
    border: none;
    height: 44px;
    width: 44px;
    margin-right: 16px;
    font-size: 18pt;

    &:active,
    &:hover {
      background-color: #e5e5e5;
    }
  }
}

.app-side {
  position: fixed;
  bottom: 0;
  width: 200px;
  top: $navbar-height;
  z-index: 30;
  .nav-item {
    line-height: normal !important;
    position: relative;
    .nav-link {
      border-left: 4px solid transparent;
      border-collapse: collapse;

      margin-top: 4px;
      padding: 12px 0 !important;
      color: #fff !important;
      font-weight: 600;

      &.router-link-exact-active {
        background-color: rgba(0, 0, 0, 0.2);
        border-left-color: #fff;
        opacity: 1;
        .nav-icon {
          opacity: 1;
        }
        *.drop-arrow {
          opacity: 0.5;
        }
      }

      span {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }

  .nav-dropdown-menu {
    padding-left: 10px;
    padding-top: 0px;
    padding-bottom: 24px;
    background-color: rgba(0, 0, 0, 0.2);

    .nav-item {
      padding: 0px !important;
      font-weight: 400;
      font-size: 15px;

      .nav-link {
        color: #fff !important;
        border-left: 0px solid transparent;
        padding-left: 16px !important;
        padding-top: 4px !important;
        padding-bottom: 4px !important;
        opacity: 0.7;

        &.router-link-exact-active {
          background: none !important;
          // font-weight: 700;
          opacity: 1;
          color: $color-accent !important;
        }
      }
    }
  }
}
.app-main {
  min-height: 92vh;

  overflow-y: auto;
}

.pt-nav {
  margin-top: $navbar-height !important;
}

.card-bdr {
  border: 1px solid #bbbbbb;
}

.bg-dull {
  background-color: $color-primary-dark;
}

.child-active {
  .nav-link {
    background-color: rgba(0, 0, 0, 0.2);
    border-left-color: #fff;
    opacity: 1;
  }
}
.no-margin-left {
  margin: 0px auto auto auto;
  padding-left: 0px;
  padding-right: 0px;
}

.border-primary-tb {
  border-color: $color-primary-light;
}

.text-primary-tb {
  color: $color-primary-dark;
}

.text-default-tb {
  color: $color-primary-dark;
  font-weight: normal;
}

.btn-primary {
  background-color: $color-primary-dark;
}

.bg-primary-tb {
  background-color: $color-primary-light;
}

.text-small {
  font-size: small;
}

.centre {
  text-align: center;
}

.hidden {
  visibility: hidden;
}

.fixed {
  position: fixed;
}

@media (min-width: 992px) {
  .modal .modal-huge {
    max-width: 67% !important;
    width: 67% !important;
  }
}

.border-left {
  border-left: gray 1px solid;
}

.sticky {
  position: sticky;
}

// .thead-light th {
//   color: $color-primary-dark !important;
//   background-color: #cce5ff !important;
//   border-color: #dee2e6 !important;
//   border-color: #b8daff !important;

// }
.no-decorate {
  text-decoration: none;
}

.tile {
  padding: 8px 16px;
  border-radius: 8px;
}
.tile.advance {
  background-color: #fbe3ff;
}
.tile.advance h2 {
  color: #b53481;
  font-size: 18px;
}
.tile.advance h6 {
  color: #843865;
  size: 13px;
}
</style>
<script>
import Projects from "./modules/projects/Projects.vue";
import ResetPassword from "@/components/reset_password/ResetPassword.vue";
import { can } from "./modules/auth/utils.js";
import DatePicker from "./components/date_picker/DatePicker.vue";
// import { gen_auth_code } from "./modules/auth/actions";
export default {
  components: { Projects, ResetPassword, DatePicker },
  mounted() {
    this.$store.dispatch("loadApps");
  },
  data() {
    return {
      can,
      toggleMenu: false,
      maindiv: 0,
    };
  },
  computed: {
    projectName() {
      return this.$store.state.project_name;
    },
    mainkey() {
      return "main" + this.maindiv.toString();
    },
    username() {
      return this.$store.state.username;
    },
    // apps() {
    //   return this.$store.getters["getApps"];
    // },
  },
  watch: {
    $route() {
      this.toggleMenu = false;
    },
  },
  methods: {
    subIsActive(input) {
      const paths = Array.isArray(input) ? input : [input];
      return paths.some((path) => {
        return this.$route.path.indexOf(path) === 0; // current path starts with this path string
      });
    },
    log_out() {
      localStorage.clear();
      this.$router.push({ path: "/login" });
    },
    reset_password() {
      this.$bvModal.show("reset-password");
    },
    showMenu(event) {
      event.stopPropagation();
      this.toggleMenu = true;
    },
    hideMenu() {
      this.toggleMenu = false;
    },
    reload() {
      this.maindiv++;
    },
    // launchApp(callback, app_id) {
    //   gen_auth_code(app_id).then((auth_code) => {
    //     const myOtherUrl = new URL(callback);
    //     myOtherUrl.search = new URLSearchParams({ auth_code });
    //     window.open(myOtherUrl.toString(),"_blank");
    //   });
    // },
  },
};
</script>