<template>
  <b-modal id="modal-1" hide-footer :title="current_route">
      <schemes v-if="current_route=='Schemes'" @change-screen="(nscreen)=>{current_route=nscreen}"></schemes>
      <scheme-options v-if="current_route=='Options'" @change-screen="(nscreen)=>{current_route=nscreen}"></scheme-options>
      <add-scheme v-if="current_route=='New Scheme'" @change-screen="(nscreen)=>{current_route=nscreen}"></add-scheme>
  </b-modal>
</template>

<script>
import Schemes from "./Schemes.vue"
import AddScheme from "./AddScheme.vue"
import SchemeOptions from "./SchemeOptions.vue"
export default {
    components:{
        Schemes,AddScheme,SchemeOptions
    },
    data(){
        return{
            current_route:"Schemes",
        }
    }
}
</script>

<style>

</style>