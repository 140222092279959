<template>
    <div>
        <b-table-simple>
            <b-tbody>
                <b-tr v-for="project in projects" :key="project.id">
                    <td>
                        <b-form-input v-model="project.name" type="text" @change="project.check_name(project.name)"></b-form-input>
                        <span class="text-danger" v-show="project.name_exists">
                            <small>Name already exists</small>
                        </span>
                    </td>
                    <td>
                        <b-button @click="rename_project(project)" variant="link" class="opt-link" :disabled="project.name_exists || project.id==1">
                            <b-icon icon="pencil" ></b-icon>
                            &nbsp; Rename
                        </b-button>
                    </td>
                    <td>
                        <b-button @click="delete_project(project)" variant="link" class="opt-link" :disabled="project.id==1">
                            <span class="text-danger">
                                <b-icon icon="trash"></b-icon>
                                &nbsp;
                                Delete
                            </span>
                        </b-button>
                    </td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
        <b-row>
            <b-col>
                <b-button variant="primary" squared @click="change_screen('Schemes')">
                    Schemes
                </b-button>
            </b-col>
        </b-row>
    </div>    
</template>
<style scoped>
    .opt-link{
        text-decoration: none;
        font-size:0.8rem;
    }
</style>
<script>
import {rename_project,delete_project,select_projects} from "./actions.js"
import {project_mixin} from "./utils.js"
export default {
    mixins:[project_mixin],
    mounted(){
        this.select_projects();
    },
    data(){
        return {
            projects:[]
        }
    },
    methods:{
        rename_project,
        delete_project,
        select_projects
    }
}
</script>