const Training = () => import("./Training.vue");
const TrainingSessions= () => import("./subroutes/trainings/Trainings.vue");
const TrainingSession= () => import("./subroutes/training/Training.vue");

const moduleRoute = {
    path: "/training/",
    component:Training,
    children:[
        {
            path:'',
            name:'training-sessions',
            component:TrainingSessions
        },
        {
            path:':training_id',
            name:'training-session',
            component:TrainingSession
        },

    ]

    };
export default router=>{
    router.addRoutes([moduleRoute]);
}
