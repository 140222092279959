import analytics from "./web-analytics";

const vanalytics = {
    /**
     * 
     * @param {Element} el 
     * @param {{value:Array;arg:string}} binding 
     */
    bind(el, binding) {
        let type = binding.arg;
        const [event_name,event_meta] = binding.value;
        el.addEventListener(type,()=>{analytics.track(event_name,event_meta)});
    }
}
export default vanalytics;