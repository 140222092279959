import { buyerRouter } from "./subroutes/buying_agents";

const Settings = () => import("./Settings.vue");
const General = () => import("./subroutes/general/General.vue");
const FarmI = () => import("./subroutes/farm_inputs/FarmInputs.vue");
const Produce = () => import("./subroutes/produce/Produce.vue");
// const Buyers = () => import("./subroutes/buyers/Buyers.vue");

const SysUsers = () => import("./subroutes/sysusers/SysUsers.vue");
const moduleRoute = {
    path: "/settings",
    component: Settings,
    children:[
        {
            path:'',
            name:'general',
            component:General
        },
        {
            path:'farm_input',
            name:'farmi',
            component:FarmI
        },
        {
            path:"produce",
            name:"sproduce",
            component:Produce
        },
        buyerRouter,
        {
            path:"sysusers",
            name:"sysusers",
            component:SysUsers
        }
    ]
};
export default router=>{
    router.addRoutes([moduleRoute]);
}