
const Dashbaord = () => import("./Dashboard.vue");
import {is_logged} from "@/modules/auth/actions.js"
const moduleRoute = {
    path: "/",
    name:"Dashboard",
    component: Dashbaord,
    beforeEnter:(to,from,next)=>{
        if(!is_logged() && to.name!='Auth'){
          next({path:'/login'})
        }else next();
      }
};
export default router=>{
    router.addRoutes([moduleRoute]);
}