<template>
    <div>
        <b-form @submit.prevent="submit_project">
            <b-form-group label="Project Name">
                <b-form-input v-model="name" type="text" @change="()=>{check_name(name)}" required></b-form-input>
                <span class="text-danger" v-show="name_exists">
                    <small>Name already exists</small>
                </span>
            </b-form-group>
            <b-form-group label="Start date">
                <b-form-datepicker v-model="created_at"></b-form-datepicker>
            </b-form-group>
            <b-form-group label="Project Description">
                <b-form-textarea v-model="description"></b-form-textarea>
            </b-form-group>
            <div class="d-flex">
                <div class="col-6">
                    <b-button variant="outline-primary" squared @click="change_screen('Schemes')" class="w-100">
                        Cancel
                    </b-button>
                </div>
                <div class="col-6">
                    <b-button variant="primary" squared type="submit" class="w-100" :disabled="name_exists">
                        Create Project
                    </b-button>
                </div>
            </div>
        </b-form>
    </div>
</template>
<script>
import {create_project,check_name} from "./actions";
import {project_mixin} from "./utils.js"
export default {
    mixins:[project_mixin],
    data(){
        return {
            name:"",
            created_at:"",
            description:"",
            name_exists:false
        }
    },
    methods:{
        create_project,
        check_name,
        submit_project(){
            const project = {name:this.name,created_at:this.created_at,description:this.description};
            this.create_project(project).then(()=>{
                this.change_screen("Schemes");
            });
        }
    }
}
</script>