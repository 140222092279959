import { axiosApiInst,axiosApiInstance } from "@/plugins/axios";

import axios from "axios";

/**
 * @typedef App
 * @property {string} id
 * @property {string} name
 * @property {string} caption
 * @property {boolean} owner 
 * @property {string} callback
 */

function login() {
    const username = this.username;
    const password = this.password;
    this.isAuthing = true;
    axios.post(process.env.VUE_APP_BACKEND+"/auth/login",{},{
        auth:{
            username,
            password
        }
    }).then(res=>{
        localStorage.setItem("access_token", res.data.access_token);
        localStorage.setItem("permissions", JSON.stringify(res.data.permissions));
        this.$store.commit('set_username', username);
        this.$analytics.track("enter_offtaker_portal", { element: "Offtaker portal", username });
        this.$store.commit('set_url_prefix', res.data.role==='buyer');
        // this.$store.dispatch('saveApps',res.data["apps"]);
        this.$nextTick(() => {
            this.$router.push({ path: '/' });
        });
    }).catch(err=>{
        if(err.response.status==401){
            this.unauthorized = true;
        }
        if(err.response.status==429){
            this.retrytime = parseInt(err.response.headers["retry-after"]);
        }
    }).finally(()=>{
        this.isAuthing = false;
    })
}
function is_logged() {
    return Boolean(localStorage.getItem("access_token"));
}
/**
 * 
 * @param {string} api_key 
 */
function sso_login(api_key) {
    axios({
        url: process.env.VUE_APP_BACKEND + "/auth" + "/authenticate",
        headers: { 'Authorization': `Bearer ${api_key}` },
        method: 'post'
    }).then(res => {
        localStorage.setItem("access_token", res.data.access_token);
        localStorage.setItem("username", res.data.username);
        localStorage.setItem("permissions", JSON.stringify(res.data.permissions));
        this.$nextTick(() => {
            this.$router.push({ path: "/" })
        });
    });
}
/**
 * 
 * @param {string} auth_code 
 */
async function sso_auth(auth_code){
    const url = process.env.VUE_APP_BACKEND+"/auth/sso_auth";
    const res = await axios({url,method:'post',params:{auth_code}});
    localStorage.setItem("access_token", res.data.access_token);
    localStorage.setItem("permissions", JSON.stringify(res.data.permissions));
    this.$store.commit('set_username', res.data.username);
    this.$analytics.track("enter_offtaker_portal", { element: "Offtaker portal", username:res.data.username });
    this.$store.commit('set_url_prefix', res.data.role==='buyer');
    this.$nextTick(() => {
        this.$router.push({ path: '/' });
    });   
}
/**
 * 
 * @typedef Demo
 * @property {string} name
 * @property {string} organization
 * @property {string} email
 * @property {string} phone_no
 * @property {string} message  
 */

/**
 * @param {Demo} data
 * @returns {Promise<string>}
 */
async function request_demo(data) {
    const res = await axiosApiInst({
        url: process.env.VUE_APP_BACKEND + "/auth" + "/:request_demo",
        data,
        method:'post'
    });
    return res.data;
}

async function gen_auth_code(app_id){
    const res = await axiosApiInstance.post(process.env.VUE_APP_BACKEND+"/auth/:gen_auth_code",null,{params:{app_id}});
    return res.data;
}
export { login, is_logged, sso_login, request_demo,sso_auth,gen_auth_code }