<template>
  <div class="ranges">
    <ul>
      <li
        class
        v-for="(range, name) in custom_ranges"
        :key="name"
        :class="{ active: key == name }"
        @click="
          ranges.clickRange(range);
          key=name;
        "
      >
        <b>{{ name }}</b>
      </li>
    </ul>
  </div>
</template>
<script>
import moment from "moment";
export default {
  props: ["ranges"],
  data() {
    return {
        key:'',
      custom_ranges: {
        "All Time": [moment("2020-01-01").toDate(), moment().add(10,'y').toDate()],
        Today: [moment().toDate(), moment().toDate()],
        Yesterday: [
          moment()
            .subtract(1, "d")
            .toDate(),
          moment()
            .subtract(1, "d")
            .toDate(),
        ],
        "Last Week": [
          moment()
            .subtract(1, "w")
            .toDate(),
          moment().toDate(),
        ],
        "This Month": [
          moment()
            .startOf("month")
            .toDate(),
          moment()
            .endOf("month")
            .toDate(),
        ],
        "Last Month": [
          moment()
            .subtract(1, "months")
            .startOf("month")
            .toDate(),
          moment()
            .subtract(1, "months")
            .endOf("month")
            .toDate(),
        ],
        "This Quarter": [
          moment()
            .startOf("quarter")
            .toDate(),
          moment()
            .endOf("quarter")
            .toDate(),
        ],
        "Last Quarter": [
          moment()
            .subtract(1, "Q")
            .startOf("quarter")
            .toDate(),
          moment()
            .subtract(1, "Q")
            .endOf("quarter")
            .toDate(),
        ],
        "This Year": [
          moment()
            .startOf("year")
            .toDate(),
          moment()
            .endOf("year")
            .toDate(),
        ],
        "Last Year": [
          moment()
            .subtract(1,"y")
            .startOf("year")
            .toDate(),
          moment()
            .subtract(1,"y")
            .endOf("year")
            .toDate(),
        ],
      },
    };
  },
  methods: {
  },
};
</script>
