const buyerMod={
    namespaced:true,
    state:()=>({
        /** @type {import("../../api").Buyer} */
        profile:{
            wallet:{
                balance:0
            },
            produce:[]
        }
    }),
    mutations:{
        save(state,profile){
         state.profile = profile;   
        },
        chngeAccount(state,wallet){
            state.profile["wallet"] = wallet;
        },
        loadProduce(state,produce){
            state.profile["produce"] = produce;
        }
    },
    getters:{
        getProfile(state){
            return state.profile;
        }
    },
    actions:{
        saveProfile({commit},profile){
            commit("save",profile);
        },
        updateWallet({commit},wallet){
            commit("chngeAccount",wallet);
        },
        updateProduce({commit},produce){
            commit("loadProduce",produce);
        }
    }

}
export default buyerMod;