const Buyers = () => import("./BuyingAgents.vue");
const ListAgents = () => import("./subcomponents/ListAgents.vue");
const Agent = () => import("./subcomponents/Agent.vue");
const buyerRouter = {
    path:"buyers",
    name:"buyers",
    component:Buyers,
    children:[
        {
            path:"",
            name:"buyerslist",
            component:ListAgents
        },
        {
            path:":agent_id",
            component:Agent
        }
    ]
}
export default buyerRouter;