import { axiosApiInstance } from "@/plugins/axios";
// eslint-disable-next-line no-unused-vars
import Vue from "vue";
/**
 * 
 * @param {string} email
 * @param {Vue} parent
 */
function recover_password(email,parent){
    axiosApiInstance({
        url:process.env.VUE_APP_BACKEND+"/auth/recover_password",
        method:"post",
        params:{email}
    }).then(()=>{
        parent.msg="Confirm request in your registered mail";
        setTimeout(()=>{
            parent.$bvModal.hide("forgot_password");
        },2000)
    }).catch(()=>{
        parent.msg="Email doesn't exist in database";
    });
}
export {recover_password}